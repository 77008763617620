import React, { ReactElement } from "react";
import MainBanner from "@components/bestvaluehomewarranty/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import BestValueHomeWarrantyWrapper from "@components/layouts/bestvaluehomewarrantyWrapper";
import WhatIsHomeWarranty from "@components/bestvaluehomewarranty/whatIsHomeWarranty";
import NewJerseyDisclaimer from "@components/shared/newJerseyDisclaimer";
const OffersList = dynamic(() => import("@components/shared/offersList"));

import WhatIsDifferent from "@components/bestvaluehomewarranty/whatIsDifferent";
import HowIsHomeWarranty from "@components/bestvaluehomewarranty/howIsHomeWarranty";
const Benefits = dynamic(
    () => import("@components/bestvaluehomewarranty/benefits"),
);

export default function BestValueHomeWarrantyHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <BestValueHomeWarrantyWrapper>
            <MainBanner />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhatIsHomeWarranty />
            <WhatIsDifferent />
            <HowIsHomeWarranty />
            <Benefits />
            <NewJerseyDisclaimer backGround="#f2f9ff" />
        </BestValueHomeWarrantyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
